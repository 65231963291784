import React from "react";

import {
  Admin,
  Delete,
  Login,
  Resource,
} from "admin-on-rest";

import hungarianMessages from "aor-language-hungarian"
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import restClient from "./restClient";
import AuthClient from "./rest/authClient";
// import {Dashboard} from './components/Dashboard'
import {
  BackendUserCreate,
  BackendUserEdit,
//   BackendUserList,
  BackendUserShow,
} from './resource/BackendUser';
// import {
//     ClientList,
//     ClientEdit,
//     ClientCreate,
//     ClientShow,
// } from './resource/Client';
import {
  CreativeCreate,
  CreativeEdit,
//   CreativeList,
  CreativeShow,
} from './resource/Creative';
// import {
//     InquiryList,
//     InquiryShow,
// } from './resource/Inquiry';
// import {
//     InquiryPackageList,
//     InquiryPackageEdit,
//     InquiryPackageCreate,
// } from './resource/InquiryPackage';
import {
  BrandEdit,
  BrandList,
  BrandOwnerModifyList,
  BrandShow,
} from './resource/PlusBrand';
import {
    PlusPackageCreate,
    PlusPackageEdit,
    PlusPackageList,
    PlusPackageModifyCreate,
    PlusPackageModifyEdit,
    PlusPackageModifyList,
    PlusPackageShow,
} from './resource/PlusPackages';
import {PlusProjectEdit, PlusProjectList, PlusProjectShow,} from './resource/PlusProjects';
import {creativePhotosSagas, errorSagas, jurySagas, plusBrandSagas, plusPackageSagas, plusProjectSagas,} from "./sagas";
import theme from './theme'
import customRoutes from './customRoutes';
import customMenu from './customMenu';
import creativePhotosReducer from './reducers/creativePhotos';
import config from './config';
import {PlusUserEdit, PlusUserShow, PlusUsersList,} from "./resource/PlusUsers";
import {EditorCreate, EditorEdit, EditorShow, EditorsList,} from './resource/Editors';
import {PlusInvitesList} from "./resource/PlusUserInvites";
// import {
//     PromoCodeCreate,
//     PromoCodeEdit,
//     PromoCodeList, PromoCodeShow
// } from "./resource/PromoCodes";
import {
    PhotoroomTemplateCreate,
    PhotoroomTemplateEdit,
    PhotoroomTemplateList
} from "./resource/PhotoroomEditingTemplate";


const authClient = AuthClient(config.apiHost);

const messages = {
    hu: hungarianMessages,
};
messages.hu.aor.navigation.no_more_results = 'Nincs több találat.'
messages.hu.aor.input = {...messages.hu.aor.input, autocomplete: {none: 'Nincs megadva'}}

const createInitialState = (resource) => {
    return {
        props: {
            name: resource
        },
        data: {},
        list: {
            ids: [],
            params: {
                sort: null,
                order: null,
                page: 1,
                perPage: null,
                filter: {}
            },
            total: 0
        }
    }
}

const initialState = {
    admin: {
        resources: {
            'creatives': createInitialState('creatives'),
            'creative-photos': createInitialState('creative-photos'),
            'reviews': createInitialState('reviews')
        }
    }
};

// const isAdmin = (permissions) => permissions.indexOf('admin') > -1

const App = () => (
    <Admin
        loginPage={Login}
        authClient={authClient}
        customSagas={
            [
                errorSagas,
                jurySagas,
                creativePhotosSagas,
                plusBrandSagas,
                plusPackageSagas,
                plusProjectSagas,
            ]
        }
        customRoutes={customRoutes}
        customReducers={{
            creativePhotos: creativePhotosReducer
        }}
        menu={customMenu}
        restClient={restClient}
        locale="hu"
        messages={messages}
        title="Photon admin"
        theme={getMuiTheme(theme)}
        initialState={initialState}
        // dashboard={Dashboard}
    >
        <Resource
            options={{label: 'Projektek'}}
            name='enterprise-projects'
            // list={PlusProjectList}
            show={PlusProjectShow}
            edit={PlusProjectEdit}
        />

        <Resource
            options={{label: 'Cégek'}}
            name='enterprise-clients'
            // list={BrandList}
            show={BrandShow}
            // create={PlusInquiryCreate}
            edit={BrandEdit}
            // remove={PlusInquiryDelete}
        />

        <Resource
            options={{label: 'Cégtulajdonos váltások'}}
            name='enterprise-client-owner-modify-requests'
            list={BrandOwnerModifyList}
            remove={Delete}
        />

        <Resource
            options={{label: 'Felhasználók'}}
            name='enterprise-users'
            // list={PlusUsersList}
            show={PlusUserShow}
            edit={PlusUserEdit}
        />

        <Resource
            options={{label: 'Meghívók'}}
            name='enterprise-invites'
            // list={PlusInvitesList}
            // create={PlusInquiryCreate}
            remove={Delete}
        />

        <Resource
            options={{label: 'Kreatívok'}}
            name="creatives"
            // list={CreativeList}
            create={CreativeCreate}
            edit={CreativeEdit}
            show={CreativeShow}
        />

        <Resource
            options={{label: 'Szerkesztők'}}
            name='editors'
            // list={EditorsList}
            show={EditorShow}
            edit={EditorEdit}
            create={EditorCreate}
        />

        <Resource
            options={{label: 'Csomagok'}}
            name='enterprise-packages'
            list={PlusPackageList}
            create={PlusPackageCreate}
            edit={PlusPackageEdit}
            show={PlusPackageShow}
        />

        <Resource
            options={{label: 'Csomag módosítás kérések'}}
            name='enterprise-package-modify-requests'
            list={PlusPackageModifyList}
            create={PlusPackageModifyCreate}
            edit={PlusPackageModifyEdit}
            remove={Delete}
        />

        {/* <Resource
            options={{label: 'Photoroom sablonok - csomagokhoz'}}
            name='photoroom-editing-templates'
            list={PhotoroomTemplateList}
            create={PhotoroomTemplateCreate}
            edit={PhotoroomTemplateEdit}
        /> */}

        <Resource
            options={{label: 'Backend felhasználók'}}
            name="backend-users"
            // list={BackendUserList}
            create={BackendUserCreate}
            edit={BackendUserEdit}
            show={BackendUserShow}
            remove={Delete}
        />

        <Resource
            options={{label: 'Fényképek'}}
            name="creative-photos"
        />

        <Resource
            options={{label: 'Értékelések'}}
            name="reviews"
        />

        <Resource name='active-editors'/>
        <Resource name='active-clients'/>
    </Admin>
);

export default App;
